.titulo{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}
@media only screen and (max-width: 768px) {
    .titulo{
        font-size: 32px;
    }
}

.cuadro-sol{
    height: 50vh;
    padding-top: 10px;
}
.sombra-opcion{
    display: flex;
    margin: auto;
}

.sombra-opcion:hover{
    cursor: pointer;
}

.icono-sombra-clasificacion{
    margin: auto;
    width: 40%;
}

.icono-sombra-arbol{
    margin: 50px auto;
    width: 60%;
}
@media only screen and (max-width: 576px) {
    .icono-sombra-arbol{
        margin: auto;
        width: 50%;
    }
    .icono-sombra-clasificacion{
        margin: auto;
        width: 40%;
    }
    .sombra-opcion{
        margin-bottom: 4vh;
    }
    .cuadro-sol{
        padding-top: 10px;
    }
}

.sombra-opcion-button{
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
    text-align: center;
    vertical-align: middle;
    width: 80%;
    min-width: 145px;
    margin-left: auto;
    margin-right: auto;
    min-height: 30px;
}

.sombra-opcion-button-true{
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
    text-align: center;
    vertical-align: middle;
    width: 80%;
    min-width: 145px;
    margin-left: auto;
    margin-right: auto;
    min-height: 30px;
    background-color: lime;
}

@media only screen and (max-width: 576px) {
    .sombra-opcion-button{
        margin-top: 20px;
        min-height: 20px;

    }

    .sombra-opcion-button-true{
        margin-top: 20px;
        min-height: 20px;

    }

}

.sombra-opcion-button-text{
    font-weight: 600;
    font-size: 16px;
    color: black;
}

.margin{
    margin: auto;
}
