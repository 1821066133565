.comoLlegarContentt{
    padding-left: 2rem;
    padding-right: 2rem;
}
.comoLlegarTitulo{
    font-family: Bebas Neue,cursive;
    color:#4f3e29 ;
    font-weight: normal ;
    font-size: 56px ;
    text-align: center !important;
}


.informacionUbicacion{
    margin-top: -20px;
    color:#4f3e29 !important;
    text-align: justify;
}

.comoLlegarContent{
    padding-left: 1rem;
    padding-right: 1rem;
}

@media screen and (max-width :767px) {
    .comoLlegarContent{
        padding: 0rem;
    }
    .informacionUbicacion{
        margin-top: -10px
    }
}

.texto-descripcion-titulo{
    font-family: 'Poppins',cursive;
    font-size: 20pt;
    color:#4f3e29 !important;
    font-weight: 500 !important;
}

.texto-descripcion{
    font-family: 'Poppins', sans-serif;
    font-size: 12pt;
}