.parallax-text{
    color:whitesmoke !important;
    font-size: 10vh !important;
}

.grey-background{
    position: absolute;
    width: 100%;
    height: 95vh;
    top: 97%;
}

.subtitle{
    color: #64503f !important;
    font-weight: normal !important;
    font-size: 6vh !important;
}

.contentHome{
    color: #64503f !important;
    width: clamp(16rem, 100vw, 70rem);
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
}

.slick-slider{
    height: 100%;
}

.slick-list{
    height: 100%;
}

.slick-track{
    height: 100%;
}

.slick-slide{
    height: 100%;
}

.slick-slide div{
    height: 100%;
}

.home-container-1{
    text-align: center;
    margin-top: 5vh;
}

.home-container-2{
    position: relative;
    height: 90vh;
    margin-bottom: 10vw;
}

.home-container-3{
    margin-bottom: 15vh;
}

.home-img-1{
    position: relative;
    width: 100%;
    object-fit: cover;
    top: -10vh;
}

.home-img-2{
    width: 100%;
    height: 50vh;
    border-radius: 15px;
}

.home-title-1{
    font-size: 5vh;
    color: #64503f;
    font-family: 'Poppins', cursive;
}

.home-title-2{
    font-family: 'Bebas Neue,cursive';
    position: absolute;
    font-size: 15vh;
    color: aliceblue;
    top: 15vh;
    left: 10vw;
}

.home-title-3{
    font-family: 'Bebas Neue,cursive';
    color: #64503f;
    font-size: 3.5vh;
}



.home-text-2{
    width: 80%;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    color: aliceblue;
    font-size: 3vh;
    top: 35vh;
    left: 10vw;
}

.home-text-3{
    font-family: 'Poppins', sans-serif;
    color: #64503f;
    font-size: 2vh;
}

.home-text-4{
    font-family: 'Poppins', sans-serif;
    color: #64503f;
    font-size: 2vh;
    text-decoration: underline;
}

.greenCircle{
    margin: auto;
    margin-bottom: 3vh;
    background-color: rgb(105, 128, 105);
    height: 10vh;
    width: 10vh;
    border-radius: 100%;
}

.cuadro-verde{
    margin: auto;
    margin-bottom: 3vh;
    height: 15vh;
    width: 15vh;
}

.home-container-4{
    background-color: rgb(247, 243, 237);
}

.home-container-content-4{
    margin: auto;
    width: 75%;
    justify-content: center;
}


.home-container-content-text-titulo-4{
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #64503f;
  

}

.home-container-content-text-parrafo-4{
    font-family: 'Poppins', sans-serif;
    color: #64503f;
    text-align: justify;
}

.home-container-5{
    background-color: rgb(255, 255, 255);
    margin-bottom: 15vh;
}

.home-container-content-5{
    margin: auto;
    width: 75%;
    justify-content: center;

}

.home-container-content-titulo-5{
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #64503f;
    text-align: center;

}

.home-container-content-parrafo-5{
    font-family: 'Poppins', sans-serif;
    color: #64503f;
    text-align: center;
}

.home-container-card-button-5{
    width: 100px;
    margin: auto;
    text-align: center;
    border-radius: 15px;
    outline-width: 1px;
    outline-style: solid;
    outline-color: rgba(112, 112, 112, 1);
}

.home-container-card-button-5:hover{
    background-color: rgb(223, 223, 223);
    cursor: pointer;
}

.home-container-card-titulo-5{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    color: rgba(112, 112, 112, 1);
    text-align: center;
}

.home-container-card-button-text-5{
    font-weight: 700;
    font-size: 12px;
    color: rgba(112, 112, 112, 1);
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;

}

.home-container-content-5{
    margin: auto;
    width: 75%;
    justify-content: center;
}

.home-container-cards{
    width: 100%;
}

.home-title-5{
    font-family: 'Bebas Neue,cursive';
    position: absolute;
    font-size: 15vh;
    color: aliceblue;
    top: 20vh;
    left: 10vw;
}

.home-title-5{
    margin-top: 50px;
    width: 80%;
    font-family: 'Poppins', sans-serif;
    position: absolute;
    color: aliceblue;
    text-align: center;
    font-size: 5vh;
}


.home-container-div-button-6{
    width: 140px;
    height: 35px;
    position: absolute;
    background-color: rgb(198, 114, 68);
    border-radius: 25px;
    font-size: 3vh;
    position: absolute;
    left: 50%;
    top: 54%;
    transform: translate(-50%, 0);
}

.home-container-div-button-6:hover{
    background-color: rgb(255, 255, 255);
    cursor: pointer;
}

.home-container-div-text-6{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: rgb(248, 232, 205);
    font-weight: 700;
    height: 30px;
    display: flex;
    align-items: end;
    justify-content: center;
}

.home-container-div-text-6:hover{
    color: rgb(198, 114, 68);
}

@media (max-width: 1600px){
    .home-container-2{
        margin-bottom: 0;
    }
}

@media (max-width: 1450px){
    .home-container-2{
        margin-bottom: 2vh;
    }
}

@media (max-width: 1120px){
    .home-container-2{
        margin-bottom: 0;
    }
    
    .home-title-2{
        font-size: 15vh;
        top: 8vh;
    }

    .home-text-2{
        top: 30vh;
        font-size: 2.5vh;
    }

    .home-title-5{
        font-size: 4vh;
        top: 8vh;
    }
    
    .home-container-div-button-6 {
        top: 30vh;
    }
}

@media (max-width: 990px){
    .home-title-2{
        top: 8vh;
    }

    .home-text-2{
        top: 25vh;
    }
}

@media (max-width: 870px){
    .home-img-1{
        position: relative;
        width: 100%;
    }

    .home-title-2{
        top: 8vh;
    }

    .home-text-2{
        top: 25vh;
    }
    
    .home-container-div-button-6 {
        top: 20vh;
    }
}

@media (max-width: 770px){
    .home-img-1{
        position: relative;
        width: 100%;
    }

    .home-title-2{
        top: 8vh;
    }

    .home-text-2{
        top: 25vh;
    }

    .home-title-5{
        font-size: 4vh;
        top: 1vh;
    }
    
    .home-container-div-button-6 {
        top: 40%;
    }
}

@media (max-width: 480px){
    .home-title-2{
        font-size: 10vh;
        top: 8vh;
    }

    .home-text-2{
        font-size: 2vh;
        top: 25vh;
    }
    
    .home-container-div-button-6 {
        top: 40%;
    }
}