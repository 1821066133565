.background_wrap {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url("BG-mision.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
}