.icon{
    width: auto;
    height: 20px;
    padding: 2px;
}

.difficulty{
    width: auto;
    height: 45px;
    padding: 2px;
}

.bold{
    font-weight: bold;
}

.tipCard{
    height: 300px;
}


.texto{
    position: absolute;
    bottom:0px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,.4));
    height: 100%;
    width: 100%;
}

Typography{
    position: relative;
    bottom: 0px;
    left: 10px;
}