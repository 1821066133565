.ant-btn-primary {
    border-color: #3f8dc8 !important;
    background: #3f8dc8 !important;
}


.site-back-top-basic {
    color: rgba(64, 64, 64, 0.6);
  }
.ant-select-selection-item{
    text-transform: uppercase;
    color:#64503f !important;
    font-weight: 500;
}

/* TODO EL CONTAINER  */

.contentRutas{
    color: #64503f !important;
    width: clamp(16rem, 90vw, 70rem);
    margin-left: auto;
    margin-right: auto;
}



.tarjeta-ruta-centrada{
    display: flex;
    justify-content: center;
}



