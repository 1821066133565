.main-content{
    font-family: 'Poppins', sans-serif;
    color: #64503f !important;
    width: clamp(16rem, 70vw, 70rem);
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
}

/*mobile*/
@media screen and (max-width: 600px) {
    .main-content{
        padding-left:0rem;
        padding-right:0rem;
    }
}

.texto-descripcion-basica{
    font-size: 20px;
    font-weight: 600;
    color: #64503f;
}

.contenido-titulo{
    font-size: 30px;
    font-weight: 600;
    color: #64503f;

}

.contenido-texto{
    font-size: 20px;
    font-weight: 500;
    color: #64503f;
}

.imagen{
    border-radius: 10px;
}
.asdf{
    background-color: white;
}