
/* PARALLAX */
.parallax-textt{
    color:whitesmoke !important;
    font-size: 5vh !important;
}
.parallax-title{
    color:whitesmoke !important;
    font-size: 10vh !important;
}
@media (max-width: 600px) {
    .parallax-textt{
        color:whitesmoke !important;
        font-size: 3vh !important;
    }
    .parallax-title{
        color:whitesmoke !important;
        font-size: 7vh !important;
    }
}

.container {
    position: relative;
    text-align: center;
    color: white;
  }
.imagen{
    object-fit: cover; /* Do not scale the image */
    object-position: center; 
    width: 100%;
    height: 80vh;
    margin-bottom: -10px;
}

@media (min-width: 900px) {
    .imagen{
        height: 100vh;
    }
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }