.subtitle{
    color: #64503f ;
    font-weight: normal ;
    font-size: 6vh ;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .subtitle{
        font-weight: normal ;
        font-size: 30px ;
    }
}
.my-search-container  .ant-input.dashboardSearch {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

/* SEARCH */
 
.my-search-container  .ant-input.dashboardSearch {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

@media (max-width: 767px) {
    .my-search-container  .ant-input.dashboardSearch {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        width: 250px;
    }
}

.search-icon{
    background-color: rgba(63, 141, 200, 1);
    width: 40px;
    height: 40px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
}

.search-icon:hover{
    cursor:pointer;
    background-color: rgba(63, 141, 200, .7);
}

.prueba{
    margin-bottom: -20px;
}