.titulo{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .titulo{
        font-size: 32px;
    }
}

.cuadro-altitud{
    height: 50vh;
}
.vehiculo-opcion{
    display: flex;
    margin: auto;
}

.vehiculo-opcion:hover{
    cursor: pointer;
}

.icono-vehiculo{
    margin-top: 50px;
    margin-left: 15%;
    margin-right: 5%;
    width: 70%;
    height: 200px;
}
.vehiculo-opcion-button{
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
    text-align: center;
    vertical-align: middle;
    width: 80%;
    min-width: 145px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    min-height: 30px;
}

.vehiculo-opcion-button-true{
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
    text-align: center;
    vertical-align: middle;
    width: 80%;
    min-width: 145px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    min-height: 30px;
    background-color: lime;
}

.vehiculo-opcion-button-text{
    font-weight: 600;
    font-size: 16px;
    color: black;
}

.margin{
    margin: auto;
}
