.popover-margin-top{
    margin-top: 0px;
}
@media (max-width: 768px) {
    .popover-margin-top{
        margin-top: 10px;
    }
}

.popover-col-center{
    display: flex;
    align-items: center;
    justify-content: center;

}
.popover-radio-center{
    display: flex;
    align-items: center;
    justify-content: center;
}

.popover-content{
    width: 250px;
}

.popover-content-filtros{
    width: 300px;
}

.popover-container{
    width: 90%;
    margin:auto;
}


.popover-individual-true{
    background-color: rgba(245, 175, 62, .3);
    width: 145px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
}

.popover-individual-false{
    width: 145px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);;
}


@media (max-width: 767px) {
    .popover-individual-true{
        background-color: rgba(245, 175, 62, .3);
        width: 150px;
        height: 30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-border-radius: 15px;
        box-shadow:
            0 0 0 3px rgba(245, 175, 62, 1);
    }

    .popover-individual-false{
        width: 150px;
        height: 30px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-border-radius: 15px;
        box-shadow:
            0 0 0 3px rgba(245, 175, 62, 1);;
    }
}
.popover-individual-true:hover{
    cursor:pointer;
}

.popover-individual-false:hover{
    cursor:pointer;
}

.popover-activated{
    background-color: rgba(245, 175, 62, .3);
}
.popover-flecha-icon{
    font-size: 10px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: rgba(100, 80, 63, 1);
    margin-top: 3px;
}
.ant-popover-inner {
    border-radius: 15px;
}
.popover-content-title-left{
    float:left;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: rgba(100, 80, 63, 1);
}

.popover-content-title-right{
    float:right;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: rgba(100, 80, 63, 1);
}

.popover-subtitle-texto{
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: rgba(100, 80, 63, 0.541);
}

.popover-ordenes-content-button{
    background-color: rgba(100, 80, 63, 0.116);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-collapse: separate;
    position: relative;
    display: flex;
    justify-content: center;
    border-style: solid;
    border-width: 3px;
    border-color: rgba(100, 80, 63, 1);
    height: 30px;
}
.popover-ordenes-content-button:hover{
    cursor: pointer;
}

.popover-content-title{
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: rgba(100, 80, 63, 1);
}
.svg-arrow{
    width: 16px;
    transform-origin: 50% 50%;
    animation-name: svgRotation;
    animation-duration: .5s;
    animation-fill-mode: forwards;

}

@keyframes svgRotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

.svg-arrow-rotated{
    width: 16px;
    transform-origin: center;
    animation-name: svgRotationBack;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}
@keyframes svgRotationBack {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

.svg-mountain{
    width: 16px;
    transform-origin: 50% 50%;
    animation-name: svgMountainRotation;
    animation-duration: .5s;
    animation-fill-mode: forwards;

}
@keyframes svgMountainRotation {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.svg-mountain-rotated{
    width: 16px;
    transform-origin: center;
    animation-name: svgMountainRotationBack;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}


@keyframes svgMountainRotationBack {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



.orden-flecha-icon{
    font-size: 10px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: rgba(100, 80, 63, 1);
    margin-top: 3px;
    background-color: white;
    width: 20px;
    height: 20px;
}

.popover-text{
    margin: auto;
    text-transform:capitalize;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.trash-icon{
    width: 18px;
    color: black;
}
.trash-icon:hover{
    cursor: pointer;
}

/* SLIDER */
.MuiSlider-thumb{
    color: rgb(255, 255, 255);
}
.MuiSlider-rail{
    color: rgba(100, 80, 63, .5);
}
.MuiSlider-track{
    color: rgba(100, 80, 63, .9);
}

/* RADIO */

.radio-text-option{
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: rgba(100, 80, 63, 1);

}