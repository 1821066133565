.contentRutaMain{
    width: clamp(300px, 95vw, 1100px);
    margin: auto;
}

.informacionRuta{
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
}

@media screen and (max-width :767px) {
    .informacionRuta{
        padding-left:1rem;
        padding-right:1rem;
    }
}

.cls{
    width : 50%;
}

.ant-tabs-tab{
    color: #363636;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
 }

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #363636 ; 
    font-weight: 500;
 }

 .ant-tabs-ink-bar {
    position: absolute;
    background: #000000;
    pointer-events: none;
 }
