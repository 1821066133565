.titulo{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.subtitulo{
    text-align: center;
    font-size: 24px;
    font-weight: 400;

}
.iconos{
    margin: auto;
    width: 150px; 
}
@media only screen and (max-width: 768px) {
    .titulo{
        font-size: 32px;
    }
    .subtitulo{
        font-size: 16px;
    }
    .iconos{
        width: 100px; 
    }
}



.iconos:hover{
    cursor: pointer;
}

.true{
   -webkit-border-radius: 10px;
   box-shadow:
       0 0 0 3px rgb(0, 255, 13);
}

.subtitulos-icons{
    font-size: 24px;
    text-align: center;
    font-weight: 400;
}

.subtitulos-true{
    color: rgb(0, 255, 13);
}