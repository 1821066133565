.submenuRuta{
    background-color: #e4e4df !important;
    height: 100%;
    color: #6e5b4b;
    text-align: center;
    padding-top: 1rem;
    font-family: Bebas Neue,cursive;
}

.submenuRuta:hover{
    color: #e4e4df !important;
    background-color: #6e5b4b !important;
    cursor: pointer;
}


.submenuRutaText{
    font-size: 2vh;
}
.submenuRutaDificultad{
    background-color: #e4e4df;
    width: 100%;
    height: 100%;
    display: flex;
}

.submenuDificultad{
    width: 100px;
    margin: auto;
}

.height{
    height: 80px;
}

@media screen and (max-width :767px) {
    .submenuDificultad{
        margin-left: 5px;
        margin-right: 5px;
    }
}
