.navbar{
    background-color:#f2be5f !important;
}

.logoImg{
    width: auto;
    height: 40px;
    padding: 0.5rem;
}

.logoImg:hover{
    cursor: pointer;
}

.buttonNav{
    font-size: 2rem !important;
    color: #da8f4b !important;
}

.buttonNav:hover{
    color: whitesmoke !important;
    background-color: #f2be5f !important;
}

.buttonNav:active{
    background: none;
}

.innerNavbar{
    padding-left: 10rem !important;
    padding-right: 5rem !important;
}

/*medium screens*/
@media (max-width: 1200px) {
    .innerNavbar{
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}

.logoImgMobile{
    width: auto;
    height: 35px;
    padding: 0.5rem;
}

.drawerMobile{
    background-color: #f2be5f !important;
    color: #64503f;
    height: 100%;
    font-weight: bold !important;
}

.options-mobile-drawer{
    font-size: 32px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: rgba(100, 80, 63, 1);
}

.options-mobile-drawer-true{
    font-size: 32px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: whitesmoke;
}

.right-aling{
  position: absolute;
  right: 0px;
}