.background-color {
    background-color: #2B2B2B;
    padding: 10rem;
    padding-top: 12rem !important;
    min-height: 100vh;
}
.main-container-planea {
    height: 80vh;
    display: grid;
    grid-template-columns: 75% 25%;
    grid-template-rows: 85% 15%;
    gap: 0px 0px;
    grid-template-areas:
        "Main Recomendaciones"
        "Botones .";
}

/*Tablet (sm)*/
@media screen and (max-width: 992px) {
    .background-color {
        background-color: #2B2B2B;
        padding: 2rem;
        padding-top: 10rem !important;
        min-height: 100vh;
        padding-bottom: 2rem !important;
    }
    .main-container-planea {
        height: 80vh;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 60% 1% 39%;
        gap: 0px 0px;
        grid-template-areas:
            "Main"
            "Botones"
            "Recomendaciones"
            ;
    }
}
/*Mobile (xs)*/
@media screen and (max-width: 768px) {
    .background-color {
        background-color: #2B2B2B;
        padding: 0px;
        padding-top: 5rem !important;
        min-height: 80vh;
        padding-bottom: 2rem !important;
    }
    .main-container-planea {
        height: 140vh;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 35% 1% 64%;
        gap: 0px 0px;
        grid-template-areas:
            "Main"
            "Botones"
            "Recomendaciones";
    }
}



.center-content {
    text-align: center;
    width: 100%;
}

.resultados-subtitle {
    text-align: center;
    font-weight: bold !important;
    margin-top: 20px;
}

.center-boton {
    text-align: center;
    width: 100%;
    top: 25%;
    transform: translate(0, -50%);
}

.planea-form2 {
    background-color: white;
    border-radius: 8px;
    margin-left: 4%;
}

.planea-form-title {
    font-weight: bold;
    padding-top: 32px;
    margin-left: 48px;
    font-size: 48px;
}

.planea-form-text {
    text-align: left;
    margin-top: 16px;
    margin-left: 48px;
    font-size: 32px;
}

.planea-form-questions {
    text-align: center;
    justify-content: center;
    margin-top: 4%;
}

.btn-form-amarillo {
    background-color: #F2BF5E;
    color: white;
    font-size: 22px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
}

.amarillo {
    color: #F2BF5E;
}

.comenzar-title {
    text-align: left;
}

.left {
    margin-left: 8%;
    justify-content: left;
    position: absolute;
    display: flex;
    justify-content: flex-end;
}

.right {
    display: flex;
    justify-content: flex-end;
    margin-right: 8%;
}

.btn-form-transparent:hover {
    background-color: green;
    color: white;
}

.btn-form-transparent:active {
    background-color: green;
    color: white;
}

.btn-form-opcion {
    font-size: 22px;
    background-color: #2B2B2B;
    color: white;
    border: none;
    border-radius: 24px;
    padding-right: 14px;
    padding-left: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
}

.btn-form-opcion:hover {
    background-color: green;
    color: white;
}

.planea-form-options {
    margin-right: 8px;
}

/* mobile */
@media screen and (max-width: 768px) {
    .planea-form-helper-text {
        margin-top: 24px;
        font-size: 18px;
        padding: 2rem;
        text-align: center;
    }
}

.planea-form-helper-text-long {
    font-size: 18px;
    width: 80%;
    margin-left: 10%;
}

.d-flex {
    display: flex;
}

.hourglass {
    height: 164px;
    width: 164px;
    margin-left: 16px;
    margin-right: 16px;
}

.btn-form-transparent {
    background-color: white;
    color: black;
    font-weight: bold;
    border: none;
    cursor: pointer;
    font-size: 24px;
}

.icon-dificultad {
    height: 64px;
    width: auto;
}

.img-icon-separator {
    margin-top: 16px;
}

.desnivel {
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 4px;
}

.bg-grey {
    background-color: #2B2B2B;
}

.bg-green {
    background-color: green;
    color: white;
}


.bottom-5 {
    margin-bottom: 8%;
}

/* Mobile */
@media (max-width: 768px) {
    .planea-form2 {
        background-color: white;
        border-radius: 8px;
        margin-left: 4%;
    }

    .planea-form-title {
        font-weight: bold;
        padding-top: 32px;
        margin-left: 16px;
        font-size: 36px;
    }

    .planea-form-text {
        text-align: left;
        margin-top: 16px;
        margin-left: 16px;
        font-size: 18px;
    }

    .icon-dificultad {
        height: 48px;
        width: 48px;
    }

    .img-icon-separator {
        margin-top: 8px;
    }

    .desnivel {
        margin-bottom: 0px;
        font-size: 9px;
    }

    .planea-form-helper-text-long {
        font-size: 12px;
        width: 90%;
        margin-left: 5%;
    }

    .hourglass {
        height: 124px;
        width: 124px;
        margin-left: 16px;
        margin-right: 16px;
    }

    .btn-form-transparent {
        background-color: white;
        color: black;
        font-weight: bold;
        border: none;
        cursor: pointer;
        font-size: 18px;
    }

    .btn-form-opcion {
        font-size: 18px;
        background-color: #2B2B2B;
        color: white;
        border: none;
        border-radius: 18px;
        padding-right: 12px;
        padding-left: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        cursor: pointer;
    }
}

/* Mobile */
@media (max-width: 992px) {
    .icon-dificultad {
        height: 56px;
        width: 56px;
    }

    .img-icon-separator {
        margin-top: 8px;
    }

    .desnivel {
        margin-bottom: 0px;
        font-size: 9px;
    }

    .btn-form-opcion {
        font-size: 18px;
        background-color: #2B2B2B;
        color: white;
        border: none;
        border-radius: 18px;
        padding-right: 12px;
        padding-left: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        cursor: pointer;
    }
}

.Main {
    grid-area: Main;
    /* background-color:  blue; */
}

.Botones {
    grid-area: Botones;
    /* background-color: red; */
}

.Recomendaciones {
    grid-area: Recomendaciones;
    /* background-color: green; */
}