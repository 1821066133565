
.comoEsLaVereda{
    padding: 2rem;
}

.iconoComoEsLaVereda{
    height: auto;
    width: 100%;
}

.texto-dificultades{
    font-family: 'Poppins', sans-serif;
    color:#4f3e29 !important;
    font-size: 15pt;
}

.comoVeredaTitulo{
    color:#4f3e29 ;
    font-family: Bebas Neue,cursive;
    font-weight: normal ;
    font-size: 56px ;
    text-align: center;
    line-height: 1.1;
}

.margin-top{
    margin-top: -40px;
}