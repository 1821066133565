.nuestros-aliados{
    margin-top: 80vh;
    background-color: #efefef;
    padding-bottom: 10vh;
}

.nuestros-aliados-container{
    margin: auto;
    width: clamp(16rem, 90%, 70rem);
}

.nuestros-aliados-titulo-container {
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .nuestros-aliados-titulo-container {
        text-align: left;
    }

    .nuestros-aliados-titulo-container h1 {
        text-align: center;
        font-size: 25px;
    }
}

.nuestros-aliados-texto-container {
    font-weight: bolder;
    font-family: 'Poppins', cursive;
    font-size: 30pt;
    color: #64503f;
}
.nuestros-aliados-texto-container-parrafo {
    font-family: 'Poppins', cursive;
    font-size: 12pt;
    color: #64503f;
    text-align: center;
}

.companias-container {
    padding-bottom: 20px;
}

.foto-compania {
    margin:auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100px;
    display: flex;
}
