.fade-r{
    opacity: 0;
}

.fade-l{
    opacity: 0;
}

.fade-t{
    opacity: 0;
}

.fade-b{
    opacity: 0;
}

.fade-right{
    animation: fadeInRight 1.5s ease-in-out;
}

.fade-left{
    animation: fadeInLeft 1.5s ease-in-out;
}

.fade-top{
    animation: fadeInTop 1s ease-in-out;
}

.fade-bottom{
    animation: fadeInDown 1.5s ease-in-out;
}

@keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(30vh);
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-30vh);
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeInTop {
    from {
      opacity: 0;
      transform: translateY(30vh);
    }
    to {
      opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-30vh);
    }
    to {
      opacity: 1;
    }
}