.mision-vision-container{
    text-align: center;
    padding-top: 5vh;
    background-color: #efefef;
}


.mision-vision-icono{
    margin: auto;
    margin-bottom: 3vh;
    height: 15vh;
    width: 15vh;
}

.mision-vision-title-1{
    font-size: 5vh;
    color: #64503f;
    font-family: 'Poppins', cursive;
}

.mision-vision-text-1{
    font-family: 'Poppins', cursive;
    font-size: 2vh;
    color: #64503f;
}