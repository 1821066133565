.icon{
    width: auto;
    height: 20px;
    padding: 2px;
}

.difficulty{
    width: auto;
    height: 45px;
    padding: 2px;
}
.parent {
    position: relative;
    top: 0;
    left: 0;
  }
  .image1 {
    position: relative;
    top: 0;
    left: 0;
    border: 1px red solid;
  }
  .image2 {
    position: absolute;
    top: 30px;
    left: 30px;
    border: 1px green solid;
  }

.titulo-text-card{
  font-size: 20px;
  font-weight: 600;
  color: #64503f;
  font-family: 'Poppins', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.area-text-card{
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  color: #857668;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.datos-card{
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  color: #857668;

}

.card-size2{
  width: 100%;
}
