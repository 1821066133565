.main-content{
    background-color: whitesmoke;
    height: 90vh;
    
    width: clamp(10rem, 90vw, 50rem);
    margin-left: auto;
    margin-right: auto;
    padding-top: 2rem;
}

.cuadro{
    position:relative;
    width: 90%;
    height: 95%;

    margin: auto;
}

.forms-content{
    
    height: 85%;
}

.asdf{
    position: absolute;
    bottom: 0;
    right: 0;
}

.asdff{
    position: absolute;
    bottom: 0;
    left: 0;

}