.titulo{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.subtitulo{
    text-align: center;
    font-size: 24px;
    font-weight: 400;

}
@media only screen and (max-width: 768px) {
    .titulo{
        font-size: 32px;
    }
    .subtitulo{
        font-size: 16px;
    }
}

.cuadro-reloj{
    height: 40vh;
}

.cuadro-reloj-opcion{
    position: absolute;
    top: 40%;
    width: 100%;
    
    
}

.cuadro-reloj-opcion-button{
    width: 80%;
    margin: auto;
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
}

.cuadro-reloj-opcion-button:hover{
    cursor: pointer;
}

.cuadro-reloj-opcion-button-true{
    background-color: lime;
}

.textoo{
    text-align: center;
    font-weight: 500;
    font-size: 32px;
}

@media only screen and (max-width: 600px) {
    .textoo{
        font-size: 16px;
        font-weight: 700;
    }
    .cuadro-reloj-opcion-button{
        width: 90%;
    }
}