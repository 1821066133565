.quienes-somos-container{
    background-color: #efefef;
    height: auto;
    width: 100%;
    margin-top: 80vh;
}

.quienes-somos-titulo-container {
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .quienes-somos-titulo-container {
        text-align: left;
    }

    .quienes-somos-titulo-container h1 {
        text-align: center;
        font-size: 25px;
    }
}

.miembros-card{
    margin-top: 16pt;
    justify-content: center;
    display: flex;
    background-color: #efefef;
}


.quienes-somos-texto-container {
    font-weight: bolder;
    font-family: 'Poppins', cursive;
    font-size: 30pt;
    color: #64503f;
}

.texto-miembros-nombre {
    font-family: 'Poppins', cursive;
    font-weight: 600;
    font-size: 16pt;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    .texto-miembros-nombre {
        padding-top: 10px;
        text-align: center;
        font-family: 'Poppins', cursive;
        font-weight: 600;
        font-size: 16pt;
        text-transform: uppercase;
    }
}


.texto-miembros-descripcion {
    font-family: 'Poppins', cursive;
    font-weight: 100;
    font-size: 12pt;
}

@media only screen and (max-width: 768px) {
    .texto-miembros-descripcion {
        text-align: center;
    }
}

.equipos-container {
    padding-left: 5%;
}