.titulo{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.subtitulo{
    text-align: center;
    font-size: 24px;
    font-weight: 400;

}
@media only screen and (max-width: 768px) {
    .titulo{
        font-size: 32px;
    }
    .subtitulo{
        font-size: 16px;
    }
}

.center-image{
    width: 60px;
    margin: auto;
}

.center-image:hover{
    cursor: pointer;
}

.gray{
    filter: grayscale(100%);
}

.rango-dificultades{
    text-align: center;
    font-size: 10px;
    font-weight: 700;
}

.button-div{
    margin: auto;
    text-align: center;
    justify-content: center;
    line-height: 30px;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    background-color: red;
}

@media only screen and (max-width: 768px) {
    .button-div{
        width: 25px;
        height: 25px;
    }
}

.button-div:hover{
    cursor: pointer;
}

.checked{
    background-color: green;
}