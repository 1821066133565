.que-hacemos {
    background-color: #efefef;
    height: auto;
    width: 100%;
    /*margin-top: 80vh;*/
}

.que-hacemos-container {
    margin: auto;
    width: clamp(16rem, 80%, 70rem);
}

.que-hacemos-titulo-container {
    text-align: center;
}

.que-hacemos-texto-container {
    font-weight: bolder;
    font-family: 'Poppins', cursive;
    font-size: 30pt;
    color: #64503f;
}

@media only screen and (max-width: 768px) {
    .que-hacemos-titulo-container {
        text-align: left;
    }

    .que-hacemos-titulo-container h1 {
        text-align: center;
        font-size: 25px;
    }
}


.que-hacemos-parrafo-container {
    font-family: 'Poppins', sans-serif;
    color: #64503f;
}

.texto-descripcion {
    font-size: 8pt;
    color: #64503f;
}

.titulo-descripcion {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16pt;
    color: #d36d38;
}

@media only screen and (max-width: 768px) {
    .titulo-descripcion {
        font-weight: 1000;
        font-size: 20pt;
        text-align: center;
    }
    .texto-descripcion {
        font-size: 12pt;
    }
}

