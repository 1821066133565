.informacionBasicaRuta{
    padding-top: 1rem;
}

.informacionBasicaContainerUbicacion{
    display: inline-block;
    text-align: left !important;
    
}

.informacionBasicaTitulo{
    color:#4f3e29 ;
    font-family: Bebas Neue,cursive;
    font-weight: normal ;
    font-size: 56px ;
    text-align: center;
    line-height: 1.1;

}

.ubicacionRutaMini{
    width: auto;
    height: 30px;
}

.caminanteRuta{
    height: 80px;
}

.informacionBasicaRutaTexto{
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    color: rgba(100, 80, 63, 1);
}
.containerInformaciones{
    padding-top: 0rem;
}
/*mobile*/
@media screen and (max-width: 600px) {
    .informacionBasicaRutaTexto{
        padding-left: 2rem;
    }
    .containerInformaciones{
        padding-top: 1rem;
    }
}


.fichaTecnicaRuta{
    border-color: #ebb255;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    height: 100%;
}

.fichaContenido{
    padding: 10px;
}



.fichaContenidoIcono{
    width: auto;
    height: 40px;
}

/*llevar*/

.fichaTecnicaRutaLlevar{
    height: 100%;
}


.fichaTituloLlevar{
    padding: 2px;
    text-align: center;
    font-family: Bebas Neue,cursive;
    background-color: #8ebd44 !important;
}


.fichaTecnicaRutaLlevar{
    border-color: #8ebd44;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.fichaContenidoTextoLlevar{
    width: 100% ;
    text-align: justify;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgba(100, 80, 63, 1);
}

/*advertencias*/

.fichaTecnicaRutaAdvertencias{
    height: 100%;
}


.fichaTituloAdvertencias{
    padding: 2px;
    text-align: center;
    font-family: Bebas Neue,cursive;
    background-color: #f1634d !important;
}


.fichaTecnicaRutaAdvertencias{
    border-color: #f1634d;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
}

.fichaContenidoAdvertenciaIcono{
    width: auto;
    height: 100px !important;
    display: flex;
    margin: auto;
}

.fichaTitulo{
    padding: 2px;
    text-align: center;
    font-family: Bebas Neue,cursive;
    background-color: #ebb255;
}

.fichaTituloTexto{
    color: whitesmoke !important;
    font-weight: normal !important;
    font-size: 3vh !important;
}
