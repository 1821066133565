.titulo{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
}

.subtitulo{
    text-align: center;
    font-size: 24px;
    font-weight: 400;

}
@media only screen and (max-width: 768px) {
    .titulo{
        font-size: 32px;
    }
    .subtitulo{
        font-size: 16px;
    }
}

.cuadro-altitud{
    height: 50vh;
}
.montana-opcion{
    display: flex;
    margin: auto;
}

.montana-opcion:hover{
    cursor: pointer;
}

.icono-montana{
    margin-top: 50px;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
}
.montana-opcion-button{
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
    text-align: center;
    vertical-align: middle;
    width: 80%;
    min-width: 145px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    min-height: 30px;
}

.montana-opcion-button-true{
    -webkit-border-radius: 15px;
    box-shadow:
        0 0 0 3px rgba(245, 175, 62, 1);
    text-align: center;
    vertical-align: middle;
    width: 80%;
    min-width: 145px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    min-height: 30px;
    background-color: lime;
}

.montana-opcion-button-text{
    font-weight: 600;
    font-size: 16px;
    color: black;
}

.margin{
    margin: auto;
}
