.mb-5{
    margin-bottom: 64px;
}

.mt-5{
    margin-top: 64px;
    padding: 0;
}

.subtitle{
    color: #64503f !important;
    font-weight: normal !important;
    font-size: 6vh !important;
}

.faqContainer {
    text-align: center;
}

.faqLink {
    text-decoration: underline;
}

.filterBar{
    background-color: grey;
}

.filterInput{
    font-size: 22px;
    color: black;
    font-weight: bold;
}

.searchIcon{
    font-size: 24px;
}